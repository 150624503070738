import React from "react";

const FillTheWorkDaySheetNotification = () => {
    return (
        <div className="d-flex" style={{color: "#66615b"}} id="bindTelegramBotNotification">
            <div>
                <h6 className="text-uppercase font-weight-bold p-0 m-0">Ведомость</h6>
                <p className="d-block">Заполните ведомость за неделю!!!</p>
            </div>
        </div>
    );
};

export default FillTheWorkDaySheetNotification;